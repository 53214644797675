import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { hyphenate, linkHover, imageHover } from '@mixins';
import mq from '@mq';
import Image from '@core/Image';

const ArticleCard = ({ title, image, date, subtitle, slug }) => (
  <Container
    to={slug}
    state={{
      modal: true,
    }}
  >
    <ArticleImage image={image} />
    <Details>
      <div>{date}</div>
      <h3>{title}</h3>
      {subtitle && <div>{subtitle}</div>}
    </Details>
  </Container>
);

const Container = styled(GatsbyLink)`
  display: grid;
  row-gap: 1.5rem;
  ${linkHover};

  ${mq.medium} {
    row-gap: var(--space-double);
  }

  h3 {
    ${hyphenate};
    text-align: left;
  }
`;

const ArticleImage = styled(Image)`
  ${imageHover};
`;

const Details = styled.div`
  display: grid;
  row-gap: 0.5rem;
`;

export default ArticleCard;
