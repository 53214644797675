import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import mq, { mqdown } from '@mq';
import { center, linkHover } from '@mixins';

import MenuSvg from '@icons/menu.svg';
import CloseSvg from '@icons/close.svg';

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded(previous => !previous);
  }, []);

  return (
    <Container expanded={expanded}>
      <MenuButton onClick={toggleExpand}>
        {!expanded && <MenuSvg />}
        {expanded && <CloseSvg />}
      </MenuButton>
      <Menu expanded={expanded}>
        <ul>
          <li>
            <Link to="/">Information</Link>
          </li>
          <li>
            <Link to="/texte" activeClassName="navigation-active">
              Texte
            </Link>
          </li>
          <li>
            <Link to="/entwuerfe" activeClassName="navigation-active">
              Entwürfe
            </Link>
          </li>
          <li>
            <Link to="/aktuelles" activeClassName="navigation-active">
              Aktuelles
            </Link>
          </li>
          <li>
            <Link to="/beteiligte" activeClassName="navigation-active">
              Beteiligte
            </Link>
          </li>
        </ul>
      </Menu>
    </Container>
  );
};

const Container = styled.nav`
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: ${props => props.expanded && 'var(--color-white);'};

  ${mq.medium} {
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: var(--color-white);
  }
`;

const MenuButton = styled.div`
  ${center};
  width: 1.375rem;
  height: 1.375rem;

  ${mq.medium} {
    display: none;
  }
`;

const Menu = styled.div`
  ${mqdown.medium} {
    display: ${props => (props.expanded ? 'flex' : 'none')};
    position: fixed;
    top: var(--navigation-height);
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding-top: var(--spacing-small);
    background: var(--color-white);
  }

  ul {
    display: grid;
    row-gap: var(--space-double);

    ${mq.medium} {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const Link = styled(GatsbyLink)`
  text-transform: uppercase;
  font: var(--font-navigation);
  color: var(--color-text);

  ${linkHover};

  &.navigation-active {
    color: var(--color-grey);
  }
`;

export default Navigation;
