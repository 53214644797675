import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Navigation from '@core/Navigation';
import Seo from '@core/Seo';
import ArticleCard from '@components/ArticleCard';
import mq from '@mq';

const ArticlesPage = ({
  data: {
    allContentfulArticle: { nodes: articles },
  },
}) => (
  <>
    <Seo pageTitle="Aktuelles" />
    <Navigation />
    <main>
      <ArticleList>
        {articles.map(article => (
          <li key={article.id}>
            <ArticleCard {...article} />
          </li>
        ))}
      </ArticleList>
    </main>
  </>
);

const ArticleList = styled.ul`
  display: grid;
  row-gap: 5rem;
  margin-top: 6.375rem;

  ${mq.medium} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--grid-gap);
    margin-top: 9.925rem;
  }
`;

export const articlesPageQuery = graphql`
  query articlesPageQuery {
    allContentfulArticle(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        subtitle
        date(formatString: "D.M.YYYY")
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 670
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            aspectRatio: 1.52
            resizingBehavior: FILL
            cropFocus: FACES
            quality: 80
          )
        }
      }
    }
  }
`;

export default ArticlesPage;
